import { styled } from "styled-components";

export const Wrapper = styled.div<{ $active: boolean }>`
  padding: 25px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.$active ? 'rgba(80, 168, 84, 1)' : 'transparent'};
  border-radius: 10px;
  border: 1px solid ${(props) => props.$active ? 'rgba(80, 168, 84, 1)' : props.theme.borderColor};
  color: ${(props) => props.$active ? '#FFFFFF' : props.theme.primaryText};
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 8px 16px;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`

export const Price = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-top: 45px;

  @media (max-width: 768px) {
    margin-top: 0;
    font-size: 18px;
  }
`

export const IconWrapper = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    min-width: 32px;
    min-height: 32px;
  }
`

export const Round = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.borderColor};

  @media (max-width: 768px) {
    visibility: hidden;
  }
`