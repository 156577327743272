import { useTranslations } from "next-intl";
import { Bundle } from "@/utils/types"
import { formatDataSize, getCurrencySymbol } from "@/shared/lib";
import Divider from "@/shared/ui/Divider";
import { Description, Field, Title, TotalSection, Wrapper } from "./styled"

export function BundleSummary({ worldwide, countries, currency, price, name, dataAmount, duration }: Bundle) {
  const t = useTranslations();

  return (
    <>
      <Wrapper>
        {worldwide ? (
          <>
            <Field>
              <Title>{t('phone_number')}</Title>
              <Description>{t('included')}</Description>
            </Field>
            <Field>
              <Title>{t('bundle')}</Title>
              <Description>{getCurrencySymbol(currency)}{price}</Description>
            </Field>
            <Field>
              <Title>{t("duration")}</Title>
              <Description>{t("unlimited")}</Description>
            </Field>
          </>
        ) : (
          <>
            <Field>
              <Title>{t("country")}</Title>
              <Description>{name}</Description>
            </Field>
            <Field>
              <Title>{t('bundle')}</Title>
              <Description>{formatDataSize(dataAmount)}</Description>
            </Field>
            <Field>
              <Title>{t("validity")}</Title>
              <Description>{t('duration_in_days', { days: duration })}</Description>
            </Field>
          </>
        )}
        <Field>
          <Title>{t("roaming")}</Title>
          <Description>{t("coverage", { count: countries.length })}</Description>
        </Field>
      </Wrapper>
      <Divider size={25} />
      <TotalSection>
        <span>{t("total")}</span>
        <span>{getCurrencySymbol(currency)}{price}</span>
      </TotalSection>
    </>
  )
}