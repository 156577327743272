import styled, { css } from "styled-components";

export const Wrapper = styled.div<{ $active: boolean }>`
  padding: ${(props) => props.$active ? '4px' : '5px'};;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border-color: ${(props) => props.$active ? 'rgba(80, 168, 84, 1)' : props.theme.borderColor};
  border-width: ${(props) => props.$active ? '2px' : '1px'};
  border-style: solid ;
  cursor: pointer;

  @media (max-width: 768px) {
    align-items: initial;
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Size = styled.div`
  margin: 20px 0;
  font-size: 24px;
  font-weight: 600;
  color: ${(props) => props.theme.primaryText};

  @media (max-width: 768px) {
    margin: 0;
  }
`

export const Responsibilities = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    align-items: flex-end;
  }
`

export const Price = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => props.theme.primaryText};
`

export const Duration = styled.div`
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.secondaryText};
`

export const IconWrapper = styled.div`
  width: 20px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;

  svg {
    min-width: 20px;
    min-height: 20px;
  }
`

export const RecommendationSection = styled.div<{ $active: boolean }>`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;

  ${(props) => props.$active && css`
    background: rgba(80, 168, 84, 1);
    color: '#FFFFFF';
  `}

  @media (max-width: 768px) {
    display: none;
  }
`