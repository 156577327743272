import { getCurrencySymbol } from "@/shared/lib";
import Icon from "@/shared/ui/Icon";
import { CheckLiteIcon } from "@/shared/ui/Icon/svg";
import { BundleSizeCardProps } from "../../model/types";
import { Price, Round, Wrapper, IconWrapper } from "./styles"

export function UnlimitedBundleSizeCard({ onClick, active, currency, price }: Omit<BundleSizeCardProps, 'duration'>) {

  return (
    <Wrapper
      onClick={onClick}
      $active={active}
    >
      {active ? <IconWrapper><Icon component={CheckLiteIcon} /></IconWrapper> : <Round />}
      <Price>{getCurrencySymbol(currency)}{price}</Price>
    </Wrapper>
  )
}