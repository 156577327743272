import { useTranslations } from "next-intl";

import { formatDataSize, getCurrencySymbol } from "@/shared/lib";
import Icon from "@/shared/ui/Icon";
import { CheckLiteIcon } from "@/shared/ui/Icon/svg";
import { BundleSizeCardProps } from "../../model/types";
import * as SC from "./styles"

export function LimitedBundleSizeCard({ onClick, active, size, currency, price, duration }: BundleSizeCardProps) {
  const t = useTranslations();

  return (
    <SC.Wrapper
      onClick={onClick}
      $active={active}
    >
      <SC.InfoWrapper>
        <SC.Size>{formatDataSize(size)}</SC.Size>
        <SC.Responsibilities>
          <SC.Price>{getCurrencySymbol(currency)}{price}</SC.Price>
          <SC.Duration>{t('duration_in_days', { days: duration })}</SC.Duration>
        </SC.Responsibilities>
      </SC.InfoWrapper>

      <SC.RecommendationSection $active={active}>
        {active && <SC.IconWrapper><Icon component={CheckLiteIcon} /></SC.IconWrapper>}
      </SC.RecommendationSection>
    </SC.Wrapper>
  )
}