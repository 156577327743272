import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Field = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
`

export const Description = styled.span`
  font-weight: 600;
  color: ${(props) => props.theme.primaryText};
`

export const Title = styled.span`
  font-weight: 400;
  color: ${(props) => props.theme.secondaryText};
`

export const TotalSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.primaryText};
  font-size: 20px;
  font-weight: 600;
`