import React from 'react';
import { useTranslations } from 'next-intl';
import Icon from '@/shared/ui/Icon';
import { GlobeIcon } from '@/shared/ui/Icon/svg';
import { StyledButton } from './styled';

type CoverageCountriesOpenModalButtonProps = React.ButtonHTMLAttributes<any>;

function CoverageCountriesOpenModalButton(props: CoverageCountriesOpenModalButtonProps) {
  const t = useTranslations();
  return (
    <StyledButton
      {...props}
      variant='outlined'
      size='small'
      label={<>
        <Icon component={GlobeIcon} width={14} height={14} />
        {t('countries')}
      </>}
    />
  );
}

export { CoverageCountriesOpenModalButton };