import React from 'react';
import { useTranslations } from 'next-intl';
import { Bundle } from 'utils/types';
import { formatDataSize, getCurrencySymbol } from '@/shared/lib';
import Divider from '@/shared/ui/Divider';
import { BundleCapabilities, BundleCapability, BundleCountry, BundleImage, BundleTitle, Wrapper } from './styled';

type BundleInfoProps = Bundle & {
  selected?: boolean;
  embeddedHeader?: React.ReactNode;
  onClick?: () => void;
};

function BundleInfo({
  image,
  name,
  dataAmount,
  duration,
  price,
  currency,
  worldwide,
  embeddedHeader,
  onClick,
  selected
}: BundleInfoProps) {
  const t = useTranslations();

  return (
    <Wrapper onClick={onClick} $selected={selected} $pointer={Boolean(onClick)}>
      <BundleTitle>
        <div>
          <BundleImage src={image} />
          <BundleCountry>{name}</BundleCountry>
        </div>
        {embeddedHeader}
      </BundleTitle>
      <Divider size={10} />
      <BundleCapabilities>
        <BundleCapability>
          {formatDataSize(dataAmount)}
        </BundleCapability>
        <BundleCapability>
          {worldwide ? t('unlimited') : t('duration_in_days', { days: duration })}
        </BundleCapability>
        <BundleCapability>
          {getCurrencySymbol(currency)}{price}
        </BundleCapability>
      </BundleCapabilities>
    </Wrapper>
  );
}

export default BundleInfo;
