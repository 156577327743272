import React, { CSSProperties } from 'react';
import { useTranslations } from 'next-intl';
import { Bundle } from 'utils/types';
import { formatDataSize } from '@/shared/lib';
import Divider from '@/shared/ui/Divider';
import { BundleCapabilities, BundleCapability, BundleCountry, BundleImage, BundleTitle, Wrapper } from './styled';

type BundleCardProps = {
  img?: string;
  title: string;
  dataSize?: string | number;
  duration: string | number;
  worldwide?: 1 | 0;
  supportedCountries?: Bundle['countries'];
  style?: CSSProperties;
  embeddedHeader?: React.ReactNode;
  embeddedFooter?: React.ReactNode;
};

function BundleCard({
  img,
  title,
  dataSize,
  duration,
  supportedCountries,
  style,
  worldwide,
  embeddedHeader,
  embeddedFooter,
}: BundleCardProps) {
  const t = useTranslations();

  return (
    <Wrapper style={style}>
      <BundleTitle>
        <div>
          <BundleImage src={img} />
          <BundleCountry>{title}</BundleCountry>
        </div>
        {embeddedHeader}
      </BundleTitle>
      <Divider size={0} />
      <BundleCapabilities>
        {dataSize && (
          <BundleCapability>
            <div>{formatDataSize(dataSize)}</div>
            <div>{t('traffic')}</div>
          </BundleCapability>
        )}
        <BundleCapability>
          <div>{worldwide ? t('unlimited') : t('duration_in_days', { days: duration })}</div>
          <div>{t('duration')}</div>
        </BundleCapability>
        {Boolean(supportedCountries?.length) && (
          <BundleCapability>
            <div>{supportedCountries?.length}</div>
            <div>{t('countries')}</div>
          </BundleCapability>
        )}
      </BundleCapabilities>
      {embeddedFooter}
    </Wrapper>
  );
}

export default BundleCard;
