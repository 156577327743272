import styled from 'styled-components';

export const BundleCountry = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${(props) => props.theme.primaryText};
`;

export const BundleImage = styled.div<{ src?: string }>`
  width: 15px;
  height: 24px;
  border-radius: 3px;
  clip-path: polygon(0 0, 67% 0, 100% 25%, 100% 100%, 0 100%);
  background: #cccccc url(${(props) => props.src}) center center no-repeat;
  background-size: cover;
`;

export const BundleCapability = styled.div`
  &:first-child, &:last-child {
    width: 1px;
    white-space: nowrap;
  };

  &:last-child {
    display: flex; 
    justify-content: flex-end;
  }
`;

export const BundleCapabilities = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: ${(props) => props.theme.primaryText};
  font-weight: 600;
`;

export const BundleTitle = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    grid-gap: 8px;
  }
`;

export const Wrapper = styled.div<{ $selected?: boolean, $pointer?: boolean }>`
  border: 1px solid ${(props) => props.$selected ? props.theme.primary : props.theme.cardsBg};
  padding: 15px;
  background: ${(props) => props.theme.stateCardBg};
  border-radius: 10px;
  cursor: ${(props) => props.$pointer ? 'pointer' : 'initial'};
`;
