import { getErrorMessage } from 'utils/common';
import { MappedDataType } from '@/utils/types';
import { BLOG_API_URL } from '@/utils/constants';
import * as profiles from './profiles';

type AuthTypes = {
  [key: string]: string;
};

function getAuthAndBaseHeaders(): AuthTypes {
  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
}

async function queryFetcher<T = unknown>(endpoint = '', options?: RequestInit): Promise<MappedDataType<T>> {
  try {
    const response = await fetch(`${endpoint.startsWith('http') ? endpoint : BLOG_API_URL.concat(endpoint)}`, {
      ...options,
      headers: { ...options?.headers, ...getAuthAndBaseHeaders() },
    });

    const data = await response.json();

    if (response.status >= 400) {
      return {
        headers: response.headers,
        data: null,
        error: getErrorMessage(data),
      };
    }

    return { headers: response.headers, data, error: null };
  } catch (e: unknown) {

    return {
      headers: null,
      data: null,
      error: getErrorMessage(e),
    };
  }
}

const api = { profiles };

export default api;
export { queryFetcher };
